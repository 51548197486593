<template>
    <div
        ref="contentContainer"
        class="content-container"
    />
</template>

<script>
export default {
    name: 'ContentRenderer',
    props: {
        content: {
            type: String,
            default: '',
        },
    },
    watch: {
        content: {
            immediate: true,
            handler() {
                this.$nextTick(() => {
                    this.renderContent();
                });
            },
        },
    },
    mounted() {
        this.renderContent();
    },
    methods: {
        renderContent() {
            if (!this.content || !this.$refs.contentContainer) {
                return;
            }

            // 清空容器
            this.$refs.contentContainer.innerHTML = '';

            // 使用 DOMParser 解析內容
            const parser = new DOMParser();
            const doc = parser.parseFromString(this.content, 'text/html');

            // 安全性處理 - 移除潛在的危險標籤和屬性
            this.sanitizeNode(doc.body);

            // 將處理後的節點添加到容器中
            Array.from(doc.body.childNodes).forEach((node) => {
                this.$refs.contentContainer.appendChild(
                    document.importNode(node, true),
                );
            });
        },

        sanitizeNode(node) {
            // 這裡可以實現更詳細的安全過濾邏輯
            // 例如：移除 script 標籤，移除事件處理屬性等

            if (!node || !node.childNodes) {
                return;
            }

            // 移除危險標籤和屬性的邏輯
            const dangerousTags = ['script', 'iframe', 'object', 'embed'];

            Array.from(node.childNodes).forEach((child) => {
                // 處理元素節點
                if (child.nodeType === 1) { // Node.ELEMENT_NODE === 1
                    // 檢查是否是危險標籤
                    if (dangerousTags.includes(child.tagName.toLowerCase())) {
                        node.removeChild(child);
                        return;
                    }

                    // 遞迴處理子節點
                    this.sanitizeNode(child);
                }
            });
        },
    },
};
</script>

<style scoped>
.content-container {
    width: 100%;
}
</style>
