<template>
    <div class="Item">
        <div class="item-content">
            <div class="item-title">
                {{ data.title }}
            </div>
            <div class="item-caption">
                {{ data.description }}
            </div>
        </div>
        <div
            v-if="labelOpen"
            class="label-group"
        >
            <button
                v-for="item in data.tags"
                :key="item"
                class="label-item"
                @click="transferLabel(item)"
            >
                {{ item }}
            </button>
        </div>
        <img
            class="item-img"
            :src="image(data.image)"
            alt=""
        >
        <div class="item-text">
            <content-renderer :content="data.content" />
        </div>
    </div>
</template>

<script>
import ContentRenderer from './ContentRenderer.vue';

export default {
    components: {
        ContentRenderer,
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
        labelOpen: {
            type: Boolean,
            default: false,
        },
        labelRouter: {
            type: String,
            default: '',
        },
    },
    data() {
        return {

        };
    },
    computed: {
        contentHtml() {
            if (!this.data.content) {
                return '';
            }

            // 直接解析HTML字串
            const parser = new DOMParser();
            const doc = parser.parseFromString(this.data.content, 'text/html');

            return doc.body.innerHTML;
        },
    },
    created() {},
    mounted() {
    },
    methods: {
        transferLabel(item) {
            this.$router.push({ name: this.labelRouter, query: { label: item } });
        },
        image(item) {
            const file = `https://${process.env.VUE_APP_BASE_URL + item}`;
            return file;
        },
    },
};
</script>

<style lang="scss">
.Item{
  display: flex;
  flex-direction: column;
  align-items: center;
  //圖片暫時顏色
  .item-img{
    background-color: #888;
    width: 600px;
    height: 450px;
    margin: 25px 0;
  }
  .item-content{
    width: 100%;
    line-height: 2;
    letter-spacing: 0.1em;
    margin-bottom: 25px;
    .item-title{
      font-size: 28px;
      font-weight: 700;
      text-align: justify;
      color: $color-blue;
    }
    .item-caption{
      font-size: 21px;
      font-weight: 700;
      text-align: justify;
    }
  }
  .label-group{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .label-item{
      color:white;
      font-size: 16px;
      font-weight: 700;
      line-height: 1;
      letter-spacing: 0.1em;
      background-color: $color-orange;
      border-radius: 2px;
      padding: 5px;
      margin-right:10px;
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 960px) {
  .Item{
    //圖片暫時顏色
    .item-img{
      width: 280px;
      height: 210px;
      margin: 15px 0;
    }
    .item-content{
      .item-title{
        font-size: 21px;
      }
      .item-caption{
        font-size: 16px;
      }
    }
    .label-group{
      .label-item{
        font-size: 14px;
        margin-right: 5px;
        margin-bottom: 5px;
      }
    }
  }
}

</style>
